import React from 'react';
import Navbar from '../components/Navbar';
import { Link } from "react-router-dom";
import CasesRecent from '../components/CasesRecent';
import CasesRecentProspect from '../components/CasesRecentProspect';
import CustomersRecent from '../components/CustomersRecent';
import ProspectsRecent from '../components/ProspectsRecent';
import CoursesRecent from '../components/CoursesRecent';
import DelegatesRecent from '../components/DelegatesRecent';
import ArticlesRecent from '../components/ArticlesRecent';
import { config } from '../Configurator'

export default function Home() {
    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Welcome back, { config().personName.split(" ")[0] }</h1>
                        <br /><br /><br />

                        {/* Enable below for Broker Lender Master Home Screen */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>Prospects</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/34">Indicative Terms</Link></div>
                        </div>
                        <CustomersRecent status="Prospect"/>
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-9"><h2>Customers</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/24">Add Customer</Link></div>
                        </div>
                        <CustomersRecent status="Customer" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-9"><h2>Cases</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/29">Add Case</Link></div>
                        </div>
                        <CasesRecent status="" /> */}


                        { /* Enable below for London Credit Home Screen */}

                        <div className="row">
                            <div className="col-md-9"><h2>Prospects</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/34">Indicative Terms</Link></div>
                        </div>
                        <ProspectsRecent status=""/>
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-9"><h2>Customers</h2></div>
                        </div>
                        <CustomersRecent status="" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-9"><h2>Cases</h2></div>
                        </div>
                        <CasesRecent status="" />

                        {/* Enable below for Broker Lender Master Home Screen Peregrine */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>Companies</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/24">Add Customer</Link></div>
                        </div>
                        <CustomersRecent status="Customer" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-6"><h2>Cases</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/3/4">Quick Quote</Link></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/3">Add Case</Link></div>
                        </div>
                        <CasesRecent status="Quotes" /> */}

                        {/* Enable below for Broker Lender Master Home Screen Lendology */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>Prospects</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/12">Add Enquiry</Link></div>
                        </div>
                        <CustomersRecent status="Prospect"/>
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-12"><h2>Cases</h2></div>
                        </div>
                        <CasesRecent status="Appointment" /> */}


                        {/* Enable below for Training Master Home Screen */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>Courses</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/31">Book a Course</Link></div>
                        </div>
                        <CoursesRecent status="Confirmed Course" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-12"><h2>Delegates</h2></div>
                        </div>
                        <DelegatesRecent status="Active" /> */}

                        {/* <h2>Latest Tasks</h2>
                        <TasksRecent /> */}


                        {/* Enable below for Manager Training Home Screen */}

                        {/* <div className="row">
                            <div className="col-md-9"><h2>Booked Courses</h2></div>
                            <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/courses">Book a Course</Link></div>
                        </div>
                        <CoursesRecent status="Confirmed Course" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-12"><h2>Expiring Qualifications</h2></div>
                        </div>
                        <DelegatesRecent status="Active" />
                        <br /> <br />
                        <div className="row">
                            <div className="col-md-12"><h2>Upcoming Courses</h2></div>
                        </div>
                        <CoursesRecent status="Confirmed Course" /> */}

                        {/* <h2>Latest Tasks</h2>
                        <TasksRecent /> */}


                    </div>
                    
                        {/* Enable below for Master Home Screen */}

                        {/* <div className="col-md-4">
                        <ArticlesRecent />
                        </div> */}

                </div>
            </div>


        </div>
    );
}