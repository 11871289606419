import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { convertDate, orderResult, searchCustomFieldsForIndex } from '../Utils';
import Loading from '../components/Loading';
import { config } from '../Configurator'

class CustomersRecent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customers: [],
            loading: true
        }
    }

    componentDidMount() {

        axios.post(config().base_url + 'GetCustomerListForUserAndCustomerTypeSortOrderJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "SearchOptions": {
              "SortType": "Decending",
              "searchText": localStorage.getItem('bo_email'),
              "isTelesalesRequest": "false",
              "endDate": "",
              "searchType": "Owner",
              "SortBy": "Code",
              "customerTypeCode": "",
              "customerStatusCode": "",
              "recordStartNo": "0",
              "customerCode": "",
              "startDate": "",
              "recordEndNo": "10000"
            }
          })
        .then(response => {
            // console.log(response.data.d.Records);
            let r = orderResult(response.data.d.Records, 'CustomerReference', 'desc');
            this.setState({loading: false, customers: r.slice(0,3)});
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    render() {
        const { customers, loading } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-12 mt-3">
                        
                        {
                            loading ? <Loading></Loading> : null
                        }
                        {
                            customers.length ?
                            customers.map(entry => { 
                                return <div key={ entry.CustomerReference }>
                                <div className="card mb-3">
                                <div className="card-body row">
                                    <div className="col-2">Reference:<br /><strong>{ entry.CustomerReference }</strong></div>
                                    <div className="col-3">Created:<br /><strong>{convertDate(entry.DateCreated)}</strong></div>
                                    <div className="col-4">Name:<br /><strong>{ entry.Firstname } { entry.Surname }</strong></div>
                                    {/* <div className="col-2">Address 4<br /><strong>{convertDate(entry.CustomerAddress.Address4)}</strong></div> */}
                                    {/* <div className="col-3">Council Area<br /><strong>{ entry.CustomFieldValue5 = searchCustomFieldsForIndex(entry.CustomFields, 5) }</strong></div> */}
                                    {/* <div className="col-2">Type of Works<br /><strong>{ entry.CustomFieldValue4 = searchCustomFieldsForIndex(entry.CustomFields, 4) }</strong></div> */}
                                    <div className="col-2">Postcode:<br /><strong>{ entry.CustomerAddress.PostCode }</strong></div>
                                    <div className="col-1">
                                        <Link to={ '/customer/' + entry.CustomerReference } className="btn-more"></Link>
                                    </div>
                                </div>
                            </div>   
                            </div> 
                            }) : 
                            <div className='card mb-3'>
                                <div className='card-body row'>
                                    <div className='col-12'>
                                        No current records
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <div className="row">
                    <div className="digitus-view-all">
                        <Link to='/customers'>View All</Link>
                    </div>
                </div>
            </div>
        )
    }

}

export default CustomersRecent;