import axios from 'axios';
import { config } from '../Configurator'
import { convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "UpdateCase";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeUpdateCaseLendKYC = (att, applicant_id, customer_reference) => {

    if(applicant_id===null) {
        alert("Applicant ID can't be null");
        return false;
    }

    console.log('passed customer reference ==' + customer_reference);

    // #1 Update case data 
    axios.post(config().base_url + endpoint, updateCasePayload(att, applicant_id, customer_reference)).then(function(response){
        let value = '';
        if(response.data.d.UpdateResult.SuccessCode==0) {

            // #2 if update goes well, get case assessor 

            value = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            if(att.form_id=='13') { // delay for sound to play 
                                setTimeout(function(){ window.location.href = "/case/" + applicant_id;  }, 2800);
                            } else {
                                window.location.href = "/case/" + applicant_id;
                            }
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });

            // window.location.href = "/thanks/" + response.data.d.Value;
        }
    }).catch(function (error){
        console.log('caught error');
        console.log(error);
    });




}

const updateCasePayload = (att, applicant_id, customer_reference) => {
    let ret = {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseApp": {
            "ApplicantID": applicant_id,
            "CustomerReference": customer_reference,
            "AvoidUpdatingBlankData":true,
            "IsUpdateCaseCustomer":true,
            "MainApplicant": {
                "Title": att.App1Title,
                "Firstname": att.App1FirstName,
                "Surname": att.App1Surname,
                "DateOfBirth": convertDateForApi(att.App1DOB),
                "Telephone": att.App1HomePhone,
                "WorkTelephone": '',
                "Mobile": att.App1MobilePhone,
                "EmailAddress": att.App1EmailAddress,
                "CurrentAddress": {
                    "HouseName": att.App1CurrentAddressHouseName,
                    "HouseNumber": att.App1CurrentAddressHouseNumber,
                    "Address1": att.App1CurrentAddress1,
                    "Address2": att.App1CurrentAddress2,
                    "Address3": att.App1CurrentAddress3,
                    "Address4": att.App1CurrentAddress4,
                    "PostCode": att.App1CurrentPostcode,
                    "TimeInYears": att.App1YearsAt
                },
                "PreviousAddresses": [
                    {
                    "id": null,
                    "HouseName": att.App1PreviousAddressHouseName,
                    "HouseNumber": att.App1PreviousAddressHouseNumber,
                    "Address1": att.App1PreviousAddress1,
                    "Address2": att.App1PreviousAddress2,
                    "Address3": att.App1PreviousAddress3,
                    "Address4": att.App1PreviousAddress4,
                    "Postcode": att.App1PreviousPostcode,
                    "TimeInYears": att.App1PrevAddressYears
                }
            ],
            "EmploymentDetails": {
              "OccupationType": "",
              "EmploymentStatus": att.App1EmploymentStatus,
              "EmployerName": att.App1EmployersName,
              "Address1": "",
              "Address2": "",
              "Address3": "",
              "Address4": "",
              "Postcode": "",
              "EmployerTelephone": "",
              "TimeInYears": att.App1TimeInEmployment,
              "TimeInMonths": "",
              "FirstIncome": "",
              "SecondIncome": "",
              "OtherIncomeDescription": "",
              "PartTimeHoursWorked": ""
            },
                "App2ID": 0
            },
            "AdditionalApplicants": [
                    {
                        "Title": att.App2Title,
                        "Firstname": att.App2FirstName,
                        "Surname": att.App2Surname,
                        "DateOfBirth": convertDateForApi(att.App2DOB),
                        "MaritalStatus": null,
                        "Telephone": att.App2HomePhone,
                        "WorkTelephone": null,
                        "Mobile": att.App2MobilePhone,
                        "EmailAddress": att.App2EmailAddress,
                        "OtherName": null,
                        "PreviousName": null,
                        "CurrentAddress": {
                            "HouseName": att.App2CurrentAddressHouseName,
                            "HouseNumber": att.App2CurrentAddressHouseNumber,
                            "Address1": att.App2CurrentAddress1,
                            "Address2": att.App2CurrentAddress2,
                            "Address3": att.App2CurrentAddress3,
                            "Address4": att.App2CurrentAddress4,
                            "PostCode": att.App2CurrentPostcode,
                            "TimeInYears": att.App2YearsAt
                        },
                        "PreviousAddresses": [
                            {
                            "HouseName": att.App2PreviousAddressHouseName,
                            "HouseNumber": att.App2PreviousAddressHouseNumber,
                            "Address1": att.App2PreviousAddress1,
                            "Address2": att.App2PreviousAddress2,
                            "Address3": att.App2PreviousAddress3,
                            "Address4": att.App2PreviousAddress4,
                            "PostCode": att.App2PreviousPostcode,
                            "TimeInYears": att.App2PrevAddressYears
                        }
                    ],
                        "App2ID": null,
                      }
                ],
            "MatterType": '',
            "Provider": '',
            "ProviderDescription": '',
            "ProviderRef": '',
            "ProviderCaseRef": '',
            "OldCaseRef": '',
            "CreditType": '',
            "NetClaim": att.NetClaim,
            "Commission": att.Commission,
            "ClaimInterest": att.ClaimInterest,
            "CustomerInitialFee": att.CustomerInitialFee,
            "CustomerCompletionFeePerc": att.CustomerCompletionFeePerc,
            "CustomerCompletionFee": att.CustomerCompletionFee,
            "CaseStatus": att.CaseStatus,
            "StatusDate": convertDateForApi(""),
            "Source": att.Source,
            "SourceRef": '',
            "SourceAffiliate": '',
            "DateCreated": convertDateForApi(""),
            //"UserCreated": null,
            "WorkflowStep": '',
            //"DaysOld": null,
            "CaseOwner": '',
            "CustomerOwner": '',
            "BrokerCode": '',
            "BrokerName": '',
            "BrokerReference": '',
            "DynamicFieldDetail": [{
                "FieldNo": 1,
                "FieldValue": ""
            },
            {
                "FieldNo": 2,
                "FieldValue": att.purpose
            },
            {
                "FieldNo": 3,
                "FieldValue": att.dynamicData3
            },
            {
                "FieldNo": 4,
                "FieldValue": att.dynamicData4
            },
            {
                "FieldNo": 5,
                "FieldValue": att.dynamicData5
            },
            {
                "FieldNo": 6,
                "FieldValue": att.dynamicData6
            },
            {
                "FieldNo": 8,
                "FieldValue": ""
            },
            {
                "FieldNo": 11,
                "FieldValue": ""
            },
            {
                "FieldNo": 12,
                "FieldValue": att.dynamicData12
            }
            ],
            "MonthlyIncome": [
                  {
                    "Code": null,
                    "TypeCode": "Salary",
                    "BasisCode": att.App1SalaryFreq,
                    "Amount": att.App1Salary,
                    "Description": "App 1 Salary",
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Pension Credit",
                    "BasisCode": att.App1PensionCreditFreq,
                    "Amount": att.App1PensionCredit,
                    "Description": "App 1 Pension Credit",
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Pension",
                    "BasisCode": att.App1PrivatePensionFreq,
                    "Amount": att.App1PrivatePension,
                    "Description": "App 1 Pension Credit",
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Rental Income",
                    "BasisCode": att.App1RentalIncomeFreq,
                    "Amount": att.App1RentalIncome,
                    "Description": "App 1 Rental Income",
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Income Support",
                    "BasisCode": att.App1PartnerContributionFreq,
                    "Amount": att.App1PartnerContribution,
                    "Description": "App 1 Partner Contribution",
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "State Benefits",
                    "BasisCode": att.App1StateBenefitFreq,
                    "Amount": att.App1StateBenefit,
                    "Description": att.App1StateBenefitType,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },

                  {
                    "Code": null,
                    "TypeCode": "Salary",
                    "BasisCode": att.App2SalaryFreq,
                    "Amount": att.App2Salary,
                    "Description": "App 2 Salary",
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Pension Credit",
                    "BasisCode": att.App2PensionCreditFreq,
                    "Amount": att.App2PensionCredit,
                    "Description": "App 2 Pension Credit",
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Rental Income",
                    "BasisCode": att.App2RentalIncomeFreq,
                    "Amount": att.App2RentalIncome,
                    "Description": "App 2 Rental Income",
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Income Support",
                    "BasisCode": att.App2PartnerContributionFreq,
                    "Amount": att.App2PartnerContribution,
                    "Description": "App 2 Partner Contribution",
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "State Benefits",
                    "BasisCode": att.App2StateBenefitFreq,
                    "Amount": att.App2StateBenefit,
                    "Description": att.App2StateBenefitType,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  }
                ],
              "MonthlyExpence": [
                  {
                    "Code": null,
                    "TypeCode": "Mortgage Repayment",
                    "BasisCode": null,
                    "Amount": att.App1Mortgage,
                    "Description": att.App1MortgageNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Loan Repayments",
                    "BasisCode": null,
                    "Amount": att.App1SecuredLoan,
                    "Description": att.App1SecuredLoanNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Council Tax",
                    "BasisCode": null,
                    "Amount": att.App1CouncilTax,
                    "Description": att.App1CouncilTaxNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Insurance (house)",
                    "BasisCode": null,
                    "Amount": att.App1BuildingsInsurance,
                    "Description": att.App1BuildingsInsuranceNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Utilities",
                    "BasisCode": null,
                    "Amount": att.App1Utilities,
                    "Description": att.App1UtilitiesNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Communications",
                    "BasisCode": null,
                    "Amount": att.App1Communications,
                    "Description": att.App1CommunicationsNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Travel",
                    "BasisCode": null,
                    "Amount": att.App1Travel,
                    "Description": att.App1TravelNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Misc",
                    "BasisCode": null,
                    "Amount": att.App1Miscellaneous,
                    "Description": att.App1MiscellaneousNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Insurance (Car/Life)",
                    "BasisCode": null,
                    "Amount": att.App1Insurances,
                    "Description": att.App1InsurancesNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Food/Housekeeping",
                    "BasisCode": null,
                    "Amount": att.App1FoodHousehold,
                    "Description": att.App1FoodHouseholdNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Other - Misc",
                    "BasisCode": null,
                    "Amount": att.App1OtherOutgoing,
                    "Description": att.App1OtherOutgoingNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },

                  {
                    "Code": null,
                    "TypeCode": "Mortgage Repayment",
                    "BasisCode": null,
                    "Amount": att.App2Mortgage,
                    "Description": att.App2MortgageNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Loan Repayments",
                    "BasisCode": null,
                    "Amount": att.App2SecuredLoan,
                    "Description": att.App2SecuredLoanNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Council Tax",
                    "BasisCode": null,
                    "Amount": att.App2CouncilTax,
                    "Description": att.App2CouncilTaxNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Insurance (house)",
                    "BasisCode": null,
                    "Amount": att.App2BuildingsInsurance,
                    "Description": att.App2BuildingsInsuranceNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Utilities",
                    "BasisCode": null,
                    "Amount": att.App2Utilities,
                    "Description": att.App2UtilitiesNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Communications",
                    "BasisCode": null,
                    "Amount": att.App2Communications,
                    "Description": att.App2CommunicationsNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Travel",
                    "BasisCode": null,
                    "Amount": att.App2Travel,
                    "Description": att.App2TravelNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Misc",
                    "BasisCode": null,
                    "Amount": att.App2Miscellaneous,
                    "Description": att.App2MiscellaneousNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Insurance (Car/Life)",
                    "BasisCode": null,
                    "Amount": att.App2Insurances,
                    "Description": att.App2InsurancesNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Food/Housekeeping",
                    "BasisCode": null,
                    "Amount": att.App2FoodHousehold,
                    "Description": att.App2FoodHouseholdNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "Code": null,
                    "TypeCode": "Other - Misc",
                    "BasisCode": null,
                    "Amount": att.App2OtherOutgoing,
                    "Description": att.App2OtherOutgoingNotes,
                    "Client": "2nd Client",
                    "MonthlyAmount": null,
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  }
                ],
            "CaseNotesList": {
                "CaseNotes": {
                    "NoteType": '',
                    "ApplicantId": '',
                    "Notes": '',
                    "CreatedDate": convertDateForApi(''),
                    "CreatedBy": '',
                    "CreatedByName": '',
                    "Status": '',
                }
            }
        }
    }


    ret.CaseApp.SecurityAddress = [];
    // handle securities 
    for(let i=0; i<100; i++) {
        if(typeof att['SecuirtyCode__' + i] !=='undefined') {
            console.log(att['SecuirtyCode__' + i]);

            ret.CaseApp.SecurityAddress.push(
                {
                    "Code": att['SecuirtyCode__' + i],
                    "HouseName": '',
                    "HouseNumber": "",
                    "Address1": "",
                    "Address2": '',
                    "Address3": '',
                    "Address4": "",
                    "Postcode": "",
                    "CurrentValue": att['CurrentValue__' + i],
                    "SecuirtyType": att['secuirtyType__' + i],
                    "SecuirtyDetails": att['secuirtyDetails__' + i],
                    "SecuirtyCharge": null,
                    "SecuirtyChargeValue": att['SecuirtyChargeValue__' + i],
                    "SecuirtyChargeDate": convertDateForApi(null),
                    "SecuirtyEquityValue": att['SecuirtyEquityValue__' + i],
                    "SecuirtyLendingValue": att['SecuirtyLendingValue__' + i],
                    "SecuirtyPurchaseValue": 0,
                    "SecuirtyPurchaseDate": convertDateForApi(''),
                    "SecuirtyMortgageValue": 0,
                    "SecuirtyMarketValue": Number(att['security_multiplier__' + i]),
                    "SecurityTitleNumber": '',
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
             );
        }
    }
    return ret;
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {
    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeUpdateCaseLendKYC
};
