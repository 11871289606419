import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "CreateCaseJSON";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeCreateCaseJSONLend = (att, applicant_id) => {


    let payload_1 = createCasePayload(att, applicant_id);
    // console.log(payload_1);
    // return false;

    axios.post(config().base_url + endpoint, payload_1).then(function (response) {
        if(response.data.d.EnquiryResult.SuccessCode==0) {

            applicant_id = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            window.location.href = "/case/" + applicant_id;
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });
            
        }
    }).catch(function (error) {
        console.log('caught error');
        console.log(error);
    });

}


const createCasePayload = (att, applicant_id, customer_reference) => {

    return {

        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseType": "LoansMort",
        "CaseParams": {
            "Params": {
                "customerCode": applicant_id,
                "matterType": att.matterType,
                "Provider": att.Provider,
                "ProviderProduct": att.ProviderProduct,
                "LoanType": att.LoanType,
                "ProductCode": att.ProductCode,
                "providerRef": null,
                "TotalLoan": att.TotalLoan,
                "Term": att.Term,
                "termPeriod": "M",
                "InterestMonthly": att.InterestMonthly,
                "UnderWriter": att.UnderWriter,
                "Processor": att.Processor,
                "AdditionalUser1": att.AdditionalUser1,
                "AdditionalUser2": "",
                "Source": att.Source,
                "SourceRef": att.SourceRef,
                "sourceInitialFeePerc": 0,
                "sourceInitialFee": 0,
                "affiliateInitialFeePerc": 0,
                "affiliateInitialFee": 0,
                "ExitFeePerc": null,
                "ExitFee": att.ExitFee,
                "InitialFeePerc": null,
                "InitialFee": att.InitialFee,
                "caseStatus": att.caseStatus,
                "customerDetails": {
                    "customerCode": applicant_id,
                    "loanAmount": att.loanAmount,
                    "othername": "",
                    "ninumber": "",
                    "ContactMethod": [
                        ""
                    ],
                    "notes": "",
                    "ClientStatus": "Customer",
                    "IsDeduplicationReq": true,
                    "BankDetail": [{
                        "BankName": "",
                        "AccountName": "",
                        "AccountNumber": "",
                        "SortCode": ""
                    }]
                },
            }
        }

    }
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {
    /**
     * {
                "jobCode": value,
                "multiRecordList": [{
                  "questions": [
                    {
                      "ItemType": "ItemField",
                      "Code": "10269",
                      "Questionnaire": null,
                      "Question": "Total Advance",
                      "Qno": 26,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor26,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                    {
                      "ItemType": "ItemField",
                      "Code": "10270",
                      "Questionnaire": null,
                      "Question": "Legal Fees",
                      "Qno": 27,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor27,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10271",
                      "Questionnaire": null,
                      "Question": "Conditions Precedent",
                      "Qno": 28,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor28,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "2",
                      "Questionnaire": null,
                      "Question": "Conditions Subsequent",
                      "Qno": 29,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor29,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10272",
                      "Questionnaire": null,
                      "Question": "Repayment",
                      "Qno": 30,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor30,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10273",
                      "Questionnaire": null,
                      "Question": "Security Cover Score",
                      "Qno": 31,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor31,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "2",
                      "Questionnaire": null,
                      "Question": "Asset Quality Score",
                      "Qno": 32,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor32,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10274",
                      "Questionnaire": null,
                      "Question": "Interest Cover Score",
                      "Qno": 33,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor33,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10275",
                      "Questionnaire": null,
                      "Question": "Exit Route Score",
                      "Qno": 34,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor34,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10276",
                      "Questionnaire": null,
                      "Question": "People Score",
                      "Qno": 35,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor35,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10277",
                      "Questionnaire": null,
                      "Question": "Operating Conditions",
                      "Qno": 36,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor36,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10278",
                      "Questionnaire": null,
                      "Question": "Ownership/Management/History",
                      "Qno": 37,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor37,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10279",
                      "Questionnaire": null,
                      "Question": "Proposal",
                      "Qno": 38,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor38,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "2",
                      "Questionnaire": null,
                      "Question": "Security",
                      "Qno": 39,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor39,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10280",
                      "Questionnaire": null,
                      "Question": "Financial Commentry",
                      "Qno": 40,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor40,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10281",
                      "Questionnaire": null,
                      "Question": "Summary Recommendation Pros",
                      "Qno": 41,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor41,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10282",
                      "Questionnaire": null,
                      "Question": "Summary Recommendation Cons",
                      "Qno": 42,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor42,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10283",
                      "Questionnaire": null,
                      "Question": "Underwriters Comments",
                      "Qno": 43,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor43,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    }
                  ]
                }],
                "UserInfo": {
                  "Username": "{{ _.username }}",
                  "Password": "{{ _.password }}"
                }
              }
     */
    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeCreateCaseJSONLend
};
