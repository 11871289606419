import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { convertDate, currency, orderResult } from '../Utils';
import Loading from './Loading';
import { config } from '../Configurator'

class CoursesRecent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cases: [],
            loading: true
        }
    }

// Results filtered by Live in JobStatus

    componentDidMount() {

        let status = this.props.status ?? "Confirmed Course";

        axios.post(config().base_url +  'GetActiveTrainingCoursesListJSON2', {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password'),
            "PrimaryGroup" : "",
            "CourseCode" : "",
            "StartDate" : "",
            "EndDate" : "",
            "MatterType" : "",
            "CourseStatus" : "",
            "CourseVenue" : ""
    })
        .then(response => {

            let filteredArray = response.data.d.Records.filter(function(itm){
                return (itm.JobStatus == status);
            });
            let r = orderResult(filteredArray, 'Code', 'desc');
            this.setState({loading: false, cases: r.slice(0,3)});
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    render() {
        const { cases, loading } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-12 mt-3">
                        
                        {
                            loading ? <Loading></Loading> : null
                        }
                        {
                            cases.length ?
                            cases.map(entry => {
                                return <div key={ entry.Code }>
                                <div className="card mb-3">
                                <div className="card-body row">
                                    <div className="col-2">Course ref:<br /><strong>{entry.Code}</strong></div>
                                    <div className="col-3">Course:<br /><strong>{entry.CourseDescription}</strong></div>
                                    <div className="col-2">Date:<br /><strong>{ convertDate(entry.CourseDate) }</strong></div>
                                    <div className="col-2">Status:<br /><strong>{entry.CourseStatus}</strong></div>
                                    <div className="col-2">Palaces:<br /><strong>{entry.AvailablePlaces}</strong></div>
                                    <div className="col-1">
                                        <Link to={ '/course/' + entry.Code } className="btn-more"></Link>
                                    </div>
                                </div>
                            </div>   
                            </div> 
                            }) : 
                            <div className='card mb-3'>
                                <div className='card-body row'>
                                    <div className='col-12'>
                                        No current records
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                <div className="row">
                    <div className="digitus-view-all">
                        <Link to='/courses'>View All</Link>
                    </div>
                </div>
            </div>
        )
    }

}

export default CoursesRecent;